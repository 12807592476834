import parse, { domToReact, HTMLReactParserOptions } from 'html-react-parser';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { acChatTawkInit, acChatTawkLoading } from '@actions/acPage';
import LoadingIcon from '@icons/Loading';
import { TThunkDispatch } from '@interfaces/index';
import { IStore } from '@interfaces/store';
import ParseHtmlStyles from './styles';

interface IParseHtmlProps {
  html: string;
}

const ParseHtml: React.FunctionComponent<IParseHtmlProps> = ({ html }) => {
  const classes = ParseHtmlStyles();
  const dispatch = useDispatch<TThunkDispatch>();

  const onInitChat = useCallback(() => dispatch(acChatTawkInit()), [dispatch]);
  const onChatLoading = useCallback((state: boolean) => dispatch(acChatTawkLoading(state)), [dispatch]);

  const loading = useSelector((state: IStore)=> state.page.chatTawkLoading);

  const openTawk = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onChatLoading(true);
    onInitChat();
  };

  const options: HTMLReactParserOptions = {
    // @ts-ignore
    replace: ({ attribs, children }) => {
      if (!attribs) {
        return;
      }
      if (['#openChat', 'javascript:void(Tawk_API.toggle())'].includes(attribs.href)) {
        return (<>
          {loading && <LoadingIcon key={`openChatLoadingIcon`} className={classes.chatLoading} />}
          <a key={`openChat`} onClick={openTawk} href={'#openChat'}>{domToReact(children, options)}</a>
        </>);
      }
    },
  };

  return (<>{parse(html, options)}</>);
};

export default ParseHtml;
