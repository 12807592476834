import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';

export default makeStyles<Theme>((theme) =>
  createStyles({
    chatLoading: {
      marginLeft: -50,
      maxHeight: 24,
      position: 'absolute !important' as 'unset',
    },
  }),
);
