import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import Link from '@components/common/Link';
import Modal from '@components/common/Modal';
import ParseHtml from '@components/common/ParseHtmlToReact';
import { IStore } from '@interfaces/store';
import { getLocalStorageValue } from '@utils/localStorage';

const name = 'last-active-notification';

const LastActiveNotification: React.FunctionComponent<{}> = () => {
  const classes = useStyles();
  const [isOpen, setOpen] = useState<boolean>(false);
  const isAuthorized = useSelector((state: IStore) => state.auth.isAuthorized);
  const lastActiveNotification = useSelector((state: IStore) => state.pageData.data.lastActiveotification);
  const { id, content, image, link, loggedInOnly, title } = lastActiveNotification || {
    content: '',
    id: 0,
    image: null,
    link: null,
    loggedInOnly: null,
    title: '',
  };

  useEffect(() => {
    const seen = getLocalStorageValue(name);
    setOpen(!seen || seen !== `${id}`);
  }, []);


  if (!lastActiveNotification || (loggedInOnly && !isAuthorized) || (loggedInOnly === false && isAuthorized)) {
    return null;
  }

  const closeModal = () => {
    window.localStorage.setItem(name, `${id}`);
    setOpen(false);
  };

  const renderContent = () => (
    <>
      {image && (
        <img src={image} alt={title} />
      )}
      {content && (
        <ParseHtml html={(content || '').replace('oembed url', 'iframe src')} />
      )}
    </>
  );

  const renderLink = () => (
    <Link legacyBehavior href={link as string}>
      <a href={link as string} title={title}>
        {renderContent()}
      </a>
    </Link>
  );

  return (
    <Modal
      open={isOpen}
      onClose={closeModal}
      classes={{ paper: classes.modal }}
      title={title || ''}
    >
      {link ? renderLink() : renderContent()}
    </Modal>
  );
};

const useStyles = makeStyles<Theme, {}>((theme) =>
  createStyles({
    modal: {
      overflow: 'auto',

      [theme.breakpoints.up('md')]: {
        height: '100%',
        maxWidth: '768px',
        width: '100%',
      },

      '& img': {
        maxWidth: '100%',
      }
    },
  }));

export default LastActiveNotification;
